import React from "react"
import Slide from "../../components/slide/slide"

import apple from "../../images/truck.png"
import appleSliced from "../../images/paisley.svg"

import badges from "../../images/badges.svg"

import code from "../../images/orchard.jpg"
import code2 from "../../images/appletree.jpg"

import shade from "../../images/shade.svg"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  autoplaySpeed: 7000,
  dots: false,
  infinite: true,
  autoplay: true,
}

const Carousel = ({ data }) => {
  return (
    <div className="carousel-container">
      {/* <img className="shade" alt="" src={shade}/> */}
      <Slider {...settings} className="overflow-hidden">
          <Slide slideHeight="500px" background={code} content={<img src={appleSliced} className="w-44 md:w-72" alt=""/>} description={<div><h3 className="xd-h1 text-center">FRESH is the best!</h3><p><img className="w-56" src={badges} alt=""/></p></div>}></Slide>
          <Slide  slideHeight="500px" background={code2} content={<img src={apple} className=""  alt=""/>} description={<div><h3 className="xd-h1 text-center">Quality Since 1954</h3><p><img className="w-56" src={badges} alt=""/></p></div>}></Slide>
      </Slider>
    </div>
  )
}

export default Carousel