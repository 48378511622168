import React from "react"
import Hero from "../components/hero/hero"
import LogoGrid from "../components/logo-grid/logo-grid"

import Layout from "../components/layout"
import Meta from "../components/seo"
import mtnFront from "../images/mtn-front.svg"
import Carousel from "../components/carousel/carousel"

import SwiperCore, { Autoplay } from 'swiper';

SwiperCore.use([Autoplay]);

const IndexPage = () => (
  <Layout>
    <Meta
      title="Fresh peeled and sliced apples in Rhode Island, Massachusetts, Connecticut, New York, Maine, New Hampshire | Greenville Apple Products" 
      description="Sliced apple products in RI for your bakery, restaurant or store" 
      url="https://www.greenvilleappleproducts.com"
    />
    <Carousel height="500"></Carousel>
    <div className="container">
    <article className="prose gray py-16">
      <h1>Fresh Peeled and Sliced Apples</h1>
      <h2>Locally Fresh Cut & Delivered Apple Slices serving New England</h2>
      <p>We are a local family ran business since 1954. We run year round and offer a superior product through out New England and New York. We love what we do and take pride In our apples! We offer all natural kosher certified non gmo preservatives on all our apple products to keep healthy and fresh. We also offer no preservatives.</p> 
      <p>Our products consist of peeled and cored sliced , diced , apple rings, apple dumplings.</p>
      <p>Or maybe you just want a delicious whole apple we sell by the bushel.</p>
      <p>We provide our products to bakeries, restaurants, hotels, wholesale bakeries, food distributors, nursing homes, colleges, and other companies that use fresh ingredients.</p>
    </article>
    </div>
    <img alt="" src={mtnFront}/>
    {/* <Parallax bgImage={mtnBack} bgImageSize="cover" bgImageAlt="the cat" strength={50} >
    <Parallax bgImage={mtnMiddle} bgImageSize="cover" bgImageAlt="the cat" strength={0} >
    <Parallax bgImage={mtnFront} bgImageSize="cover" bgImageAlt="the cat" strength={-200} >
      <div className="h-20 md:h-96"></div>
      </Parallax>
      </Parallax>
      </Parallax> */}
    <LogoGrid></LogoGrid>
  </Layout>
)

export default IndexPage
