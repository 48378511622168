import React from "react"
import "./logo-grid.scss"
import { Link } from "gatsby"
import ap1 from "../../images/apple-product-1.png"
import ap2 from "../../images/apple-product-2.png"
import ap3 from "../../images/apple-product-3.png"
import ap4 from "../../images/apple-product-4.png"

import plank from "../../images/plank.jpg"

function LogoGrid() {
  return (
    <div style={{ backgroundImage: `url("${plank}")` }}>
        <div className="container p-10">
        <h1 className="text-xl text-white text-center mb-4">Featured products...</h1>
            <div class="grid grid-cols-2 lg:grid-cols-4 gap-2">
                <div className="p-4 text-white text-center">
                  <img data-sal="zoom-in" data-sal-duration="2000" data-sal-delay="0" data-sal-easing="ease" className="md:w-3/4 m-auto" src={ap1} alt="Sliced Apples"/>
                  <p class="mt-4 text-lg">Sliced Apples (Various Sizes)</p>
                </div>
                <div className="p-4 text-white text-center">
                  <img data-sal="zoom-in" data-sal-duration="2000" data-sal-delay="300" data-sal-easing="ease" className="md:w-3/4 m-auto" src={ap2} alt="Dumplings"/>
                  <p class="mt-4 text-lg">Dumplings</p>
                </div>
                <div className="p-4 text-white text-center">
                  <img data-sal="zoom-in" data-sal-duration="2000" data-sal-delay="600" data-sal-easing="ease" className="md:w-3/4 m-auto" src={ap3} alt="Sliced Apple Rings"/>
                  <p class="mt-4 text-lg">Sliced Apple Rings (Various Sizes)</p>
                </div>
                <div className="p-4 text-white text-center">
                  <img data-sal="zoom-in" data-sal-duration="2000" data-sal-delay="900" data-sal-easing="ease" className="md:w-3/4 m-auto" src={ap4} alt="Diced apples"/>
                  <p class="mt-4 text-lg">Diced Apples (Various Sizes)</p>
                </div>
            </div>
            <div className="flex">
            <Link to={`/products`} className="text-center text-lg text-white mt-4 border border-white p-4 inline-block mx-auto">View all Products</Link>
            </div>
        </div>
    </div>
  )
}

export default LogoGrid
