import React from "react"
import { Parallax } from "react-parallax"
import "./hero.scss"
import logo from "../../images/logo.svg"
import paisley from "../../images/paisley.svg"
import served from "../../images/served.jpg"

function Hero() {

  return (
    <Parallax
      bgImage={served}
      bgImageAlt="" 
      strength={200}
    >
      <div className="container xd-hero">
        <div className="xd-hero__logo">
            <img className="w-2/6 md:w-1/2" src={logo} alt="XS Labs Logo"/>
        </div>
        <div className="xd-hero__illustration">
            <img 
                data-sal="slide-up"
                data-sal-duration="2000" 
                data-sal-delay="300" 
                data-sal-easing="ease" 
                className="w-3/6 md:w-2/3 mx-auto" src={paisley} alt=""/>
        </div>
      </div>
    </Parallax>
  )
}

export default Hero
